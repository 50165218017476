import React from 'react';

const JSONParser = ({ jsonData }) => {
  const data = jsonData;

  const renderObject = (obj) => {
    return Object.entries(obj).map(([key, value]) => {      
      if (typeof value === 'object') {
        // Handle nested object
        return (
          <>
            <strong>
              <div>{key}:</div>
            </strong>
            <div style={{paddingLeft:20}}>
                {
                  Object.entries(value).map(([key1, value1]) => {
                    // if (typeof value1 === 'object') {
                    //   // Handle nested object
                    //   return (
                    //     <>
                    //       <div>
                    //         <strong>
                    //           <div>{key1}::</div>
                    //           <br></br>
                    //         </strong>
                    //       </div>
                    //       <br></br>
                    //     </>
                    //   );
                    // } else {
                      return (
                        <>
                          <div className='divObjectives'>
                            <ol>
                              <li className='d-flex' key={key + key1}>    
                                <div className="d-inline-block">{key1 + "."}</div><div className="divNumbered d-inline-block">{value1}</div>
                              </li>
                            </ol>
                          </div>
                        </>
                      );
                    // }
                  })
                }
            </div>
            <br></br>
          </>
        );
      } else {
        return (
          <>
            <div key={key}>
                { key === "Title" ?
                  <strong>{key}: {value}</strong>
                  :
                  <><strong>{key}:</strong> {value}</>
                }
            </div>
            <br></br>
          </>
        );
      }
    });
  };

  return renderObject(data);
};

export default JSONParser;
