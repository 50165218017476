import React, { useContext, useState, useEffect} from "react";
import { Context } from "../Context";
import { Button } from "@bain/design-system"
import callDownloadAPIs from "../apis/api-download";
import LoadingImage from '../assets/processing.gif'
import { appInsights } from '../appinsights/appInsights';
import JSONParser from '../components/JSONParser'
import { toast } from "react-toastify";

const PreviewDownload = () => {

  const { callGuide } = useContext(Context);
  const { loading } = useContext(Context);
  const { userId } = useContext(Context);
  
  const messages = [
    'This may take a few minutes. Creating your draft call guide now.',
    'Currently searching a database of 3,000 call guides. This might take a few minutes.',
    'You know what they say – you can’t rush art.',
    'Rome wasn’t built in a day, but your call guide will be (I hope).',
  ];
  const [messageIndex, setMessageIndex] = useState(0);
  const [showLastMessage, setShowLastMessage] = useState(false);

  const currentMessage = showLastMessage ? messages[messages.length - 1] : messages[messageIndex];

  useEffect(() => {
    let interval;
    let timeout;

    if (loading) {
      setMessageIndex(0); // Reset the message index to 0 when loading starts
      setShowLastMessage(false);
      disableChatBox();
      interval = setInterval(() => {
        setMessageIndex((prevIndex) => {
          if (prevIndex < messages.length - 1) {
            return prevIndex + 1; // Increment the index to cycle through the messages
          } else {
            setShowLastMessage(true); // Show the last message after 90 seconds
            clearInterval(interval);
            return prevIndex; // Return the current index without incrementing
          }
        });
      }, 60000);

      timeout = setTimeout(() => {
        setShowLastMessage(true); // Show the last message after 90 seconds
        clearInterval(interval);
      }, 180000);
    } else {
      setShowLastMessage(true); // Show the last message when loading completes
      clearInterval(interval);
      clearTimeout(timeout);
      enableChatBox();
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [loading]);

  const disableChatBox = () => {
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    inputElement.placeholder = "Processing the previous request, please wait.."
    inputElement.disabled = true;
    buttonElement.disabled = true;
  };

  const enableChatBox = () => {
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    inputElement.placeholder = "Write your message here"
    inputElement.disabled = false;
    buttonElement.disabled = false;
  };

  const handleButtonClick = async () => {
    try
    {
      callDownloadAPIs(callGuide);
      appInsights.trackEvent("Download Word File", {userId:userId, callGuide: callGuide});
    }
    catch(error)
    {    
      toast.error("Error: downloading draft call guide operation failed, please try again.",{autoClose:false});
      appInsights.trackException({ error: error, properties: {data: callGuide} });
    }
  };

  return (
    <div className="react-chatbot-kit-chat-inner-container">
      <div className="react-chatbot-kit-chat-header">Preview / download screen </div>
      <div className="react-chatbot-kit-chat-message-container openAIResponse">
        {loading ? <div className="loadingImage"><center><img src={LoadingImage} alt="Chatbot avatar" /><div>{currentMessage}</div></center></div> : ""}
        {callGuide ? <JSONParser jsonData={callGuide}></JSONParser> : 
        <div>
          <strong>Here are some considerations to keep in mind while using this tool:</strong>
          <ul>
            <li key="li1">
              The tool’s <strong>environment is secure</strong>, feel free to <strong>directly copy details of your case scope and specific case context</strong> into the draft generator 
            </li>
            <li key="li8">
              Reminder: this tool generates a <strong>first draft</strong> guide, and should be edited and refined before using to conduct an interview
            </li>
            <li key="li2">
              After the draft call guide has been generated, the <strong>tool is responsive to feedback</strong>
            </li>
            <ul>
                <li key="li9">
                  The current product <strong>does not have an ‘undo’ function;</strong> please take care when requesting changes via the chatbot
                </li>
                <li key="li3">
                    If you’d like the questions to be <strong>more specific and nuanced</strong>, please ask the tool
                </li>
                <li key="li4">
                    If you’d like the tool to <strong>include a list of competitors</strong>, please ask the tool
                </li>
                <li key="li5">
                    If there are specific <strong>questions you’d like to include</strong>, please ask the tool
                </li>
                <li key="li6">
                  If the tool <strong>generated an unexpected response that you’d like to fix</strong>, please ask the tool
                </li>
                <li key="li10">
                  <strong>Refinements to particular sections</strong> may not work as expected; if that occurs, please pull down the guide and edit it offline
                </li>
            </ul>
            <li key="li7">
              Please reference the user guide (link on the top left) for more common types of feedback submitted to the tool
            </li>
          </ul>
          </div>
          }
      </div>
      <div className="container1">
        <div className="vertical-center">
          {callGuide ?
            <Button className="downloadbutton button"
              onClick={() => handleButtonClick("Yes")}>
                 Download draft guide
              <span className="button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" className="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
            </Button>
            : ""
          }
        </div>
      </div>
    </div>
  );
};

export default PreviewDownload;