export default function UserInstructions() {
  return (
    <section className="divObjectives">
      Let me know of any adjustments you’d like me to make to the sections / questions within the draft. Example prompts to refine your guide are below (more example prompts included in the user guide):
      <ul>    
        <li>Can you add more nuance to the Competitive Landscape section?</li>
        <li>Can you add 2 questions to the Market trends section asking about the impact of new policies on pricing?</li>
      </ul>
    </section>
  );
}